import React from 'react';
import { IntlProvider, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { contactUs } from './Data/mainDataFile';
import adress from '../assets/icons/adress.svg';
import phon from '../assets/icons/phon.svg';
import mobile from '../assets/icons/mobile.svg';

function ContactUs({ lang }) {
  return (
    <>
      <IntlProvider locale={lang} messages={contactUs[lang]}>
        <div data-aos={`${lang === 'En' ? 'fade-left' : 'fade-right'}`} className="contactUs">
          <h3>
            <FormattedMessage
              id="titl"
              defaultMessage="some default one"
              values={{ lang }}
            />
          </h3>
          <div className="item">
            <img src={adress} alt="adress" />
            <h4>
              <FormattedMessage
                id="address"
                defaultMessage="some default one"
                values={{ lang }}
              />
            </h4>
          </div>
          <div className="item">
            <img src={phon} alt="phon" />
            <h4>
              <FormattedMessage
                id="phon"
                defaultMessage="some default one"
                values={{ lang }}
              />
            </h4>
          </div>
          <div className="item">
            <img src={mobile} alt="adress" />
            <h4>
              <FormattedMessage
                id="Mobile"
                defaultMessage="some default one"
                values={{ lang }}
              />
              {/* <FormattedMessage
                id="Mobile2"
                className="Mobile2"
                defaultMessage=" "
                values={{ lang }}
              /> */}
              <a href={`tel:${contactUs.En.Mobile3}`} className="Mobile2">{contactUs.En.Mobile2}</a>
            </h4>
          </div>
        </div>
      </IntlProvider>
    </>
  );
}

ContactUs.propTypes = {
  lang: PropTypes.string.isRequired,
};
export default ContactUs;
