import React, { useState, useRef } from 'react';
import { IntlProvider, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import emailjs from '@emailjs/browser';
import { header, contact } from './Data/mainDataFile';
import ContactUs from './ContactUs';
import teejan from '../assets/Teejan.svg';
import img from '../assets/HeaderIcon.svg';
import backImage from '../assets/backImage.svg';
import backImage1 from '../assets/backImage1.svg';
import Footer from './Footer';

function ContactMe({ lang }) {
  const [name, setName] = useState('');
  const [mail, setMail] = useState('');
  const [meggage, setMeggage] = useState('');
  const [msg, setMsg] = useState('');
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(`${process.env.REACT_APP_SERVICE_ID}`,
        `${process.env.REACT_APP_TEMPLATE_ID}`,
        form.current, {
          publicKey: `${process.env.REACT_APP_PUBLIC_KEY}`,
        })
      .then(
        (result) => {
          console.log(result.text);
          setMsg('تم إرسال الرسالة...');
          setMail('');
          setMeggage('');
          setName('');
        },
        (error) => {
          console.log(error.text);
        },
      );
  };

  return (
    <div data-aos="fade-up" className="sec">
      <div className={`main  ${lang === 'En' ? 'mainEn' : 'mainAr'}`} id="ContactMe">
        <div className="up sec">
          <div className="contactTitle ">
            <div className="title">
              <img src={img} alt="img3" />
              <IntlProvider locale={lang} messages={header[lang]}>
                <h2>
                  <FormattedMessage
                    id="Contact"
                    defaultMessage="some default one"
                    values={{ lang }}
                  />
                </h2>
              </IntlProvider>
              <img src={img} alt="img4" />
            </div>
            <IntlProvider locale={lang} messages={contact[lang]}>
              <h3 className="contactDesc">
                <FormattedMessage
                  id="titl"
                  defaultMessage="some default one"
                  values={{ lang }}
                />
              </h3>
            </IntlProvider>
          </div>
          <div className="div2">
            <div className="contact-form" data-aos={`${lang === 'En' ? 'fade-left' : 'fade-right'}`}>
              <IntlProvider locale={lang} messages={contact[lang]}>
                <div className="formDiv">

                  <form
                    ref={form}
                    className="form"
                    onSubmit={sendEmail}
                  >
                    <div className="formFilds">
                      <label htmlFor="user_name" className="fild">
                        <FormattedMessage
                          id="name"
                          defaultMessage="some default one"
                          values={{ lang }}
                        />
                        <input
                          type="text"
                          value={name}
                          id="user_name"
                          name="user_name"
                          className="form-i"
                          maxLength="30"
                          placeholder={`${lang === 'Ar' ? 'ادخل اسمك ...' : 'Enter your name...'}`}
                          required
                          onChange={(e) => setName(e.target.value)}
                        />
                      </label>
                      <label htmlFor="user_email" className="fild">
                        <FormattedMessage
                          id="email"
                          defaultMessage="some default one"
                          values={{ lang }}
                        />
                        <input
                          type="email"
                          value={mail}
                          id="user_email"
                          name="user_email"
                          className="form-i"
                          placeholder={`${lang === 'Ar' ? 'ادخل بريدك الاكتروني ...' : 'Enter your email...'}`}
                          required
                          onChange={(e) => setMail(e.target.value)}
                        />

                      </label>
                    </div>
                    <label htmlFor="message" className="fild1">
                      <FormattedMessage
                        id="msg"
                        defaultMessage="some default one"
                        values={{ lang }}
                      />
                      <textarea
                        id="message"
                        name="message"
                        value={meggage}
                        className="form-i"
                        rows="6"
                        maxLength="500"
                        placeholder={`${lang === 'Ar' ? 'ادخل الرسالة ...' : 'Enter your message...'}`}
                        required
                        onChange={(e) => setMeggage(e.target.value)}
                      />
                    </label>
                    <span className="text-success">
                      {msg}
                    </span>
                    <input type="submit" className="btn" value={`${lang === 'Ar' ? 'أرســــل' : 'Send'}`} />
                  </form>
                </div>

              </IntlProvider>
            </div>
            <ContactUs lang={lang} />
            <div className="backImage">
              <div className="backimg ">
                <img src={backImage} alt="img3" className={`backImageimg  ${lang === 'En' ? 'transform' : ''}`} />
                <img src={backImage1} alt="img3" className="backImage1" />
              </div>
              <img src={teejan} alt="Teejan" className="teejan " />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
ContactMe.propTypes = {
  lang: PropTypes.string.isRequired,
};
export default ContactMe;
