import React from 'react';
import ReactDOM from 'react-dom/client';
import { register } from 'swiper/element/bundle';
import './index.css';
import App from './App';
// register Swiper custom elements
register();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
