import BBC from '../../assets/ourCustomers/BBC.svg';
import FaceBook from '../../assets/ourCustomers/FaceBook.svg';
import MTN from '../../assets/ourCustomers/MTN.svg';
import Mazzika from '../../assets/ourCustomers/Mazzika.svg';
import RT from '../../assets/ourCustomers/RT.svg';
import Rotana from '../../assets/ourCustomers/Rotana.svg';
import SabaCkach from '../../assets/ourCustomers/SabaCkach.svg';
import SabaPhone from '../../assets/ourCustomers/SabaPhone.svg';
import w from '../../assets/ourCustomers/W.svg';
import YemenD from '../../assets/ourCustomers/YemenD.svg';
import lobitel from '../../assets/ourCustomers/lobitel.svg';
import mbc from '../../assets/ourCustomers/mbc.svg';
import yemenMobile from '../../assets/ourCustomers/yemenMobile.svg';
import velti from '../../assets/ourCustomers/velti.svg';

const ourClintsData = [
  {
    id: '1',
    src: BBC,
    alt: 'BBC',
  },
  {
    id: '2',
    src: FaceBook,
    alt: 'FaceBook',
  },
  {
    id: '3',
    src: lobitel,
    alt: 'lobitel',
  },
  {
    id: '4',
    src: Mazzika,
    alt: 'Mazzika',
  },
  {
    id: '5',
    src: RT,
    alt: 'RT',
  },
  {
    id: '6',
    src: Rotana,
    alt: 'Rotana',
  },
  {
    id: '7',
    src: SabaCkach,
    alt: 'SabaCkach',
  },
  {
    id: '8',
    src: SabaPhone,
    alt: 'SabaPhone',
  },
  {
    id: '9',
    src: YemenD,
    alt: 'YemenD',
  },
  {
    id: '10',
    src: lobitel,
    alt: 'lobitel',
  },
  {
    id: '11',
    src: mbc,
    alt: 'mbc',
  },
  {
    id: '12',
    src: w,
    alt: 'w',
  },
  {
    id: '13',
    src: yemenMobile,
    alt: 'yemenMobile',
  },
  {
    id: '14',
    src: MTN,
    alt: 'MTN',
  },
  {
    id: '15',
    src: velti,
    alt: 'velti',
  },
];

export default ourClintsData;
