const header = {
  En: {
    teejan: 'Teejan',
    Services: 'Services',
    skils: 'Our team skils',
    Contact: 'Contact us',

  },
  Ar: {
    teejan: 'تيجان',
    Services: 'خدماتنا',
    skils: 'خبرات فريقنا',
    Contact: 'تواصل معنا',
  },
};

const about = {
  En: {
    titl: 'Our identity...from our heritage',
    desc: `We are proud to add the Yemeni identity to everything we offer.
         We strive to place our clients in a prominent and deserved position among their competitors.`,
  },
  Ar: {
    titl: 'هويتنا... من تراثنا',
    desc: `
        نفخر بإضافة الهوية اليمنية إلى كل ما نقدم. ونسعى جاهدين إلى وضع عملائنا
        في مكانة بارزة ومستحقة بين منافسيهم.`,
  },
};
const contact = {
  En: {
    titl: 'We are looking forward to communicating with you, continuing support, and the success of your project.',
    name: 'Name:',
    email: 'Email:',
    msg: 'message:',
  },
  Ar: {
    titl: 'نحن في انتظار التواصل معكم وتقديم الدعم اللازم لضمان نجاح مشروعكم.',
    name: 'الاسم:',
    email: 'البريد الالكتروني:',
    msg: 'الرسالة:',
  },
};
const contactUs = {
  En: {
    titl: 'Contact Us:',
    address: "Address: Nawakshot St.- Al Washali Building - Sana'a - Republic of Yemen",
    phon: 'Telephone: 101080893',
    Mobile: 'Mobile: ',
    Mobile2: '00967-770-000-272',
    Mobile3: '00967770000272',
    email: 'E-Mail: info@teejan.net',
    web: 'Web Site: www.teejan.net',
  },
  Ar: {
    titl: 'للتواصل معنا:',
    address: 'العنوان: شارع نواكشوط - عمارة الوشلي - صنعاء - الجمهورية اليمنية',
    phon: 'هاتف: 101080893',
    Mobile: 'موبايل: ',
    Mobile2: '00967-770-000-272',
    Mobile3: '00967770000272',
    email: 'البريد الالكتروني : info@teejan.net',
    web: 'الموقع الالكتروني : www.teejan.net',
  },
};

export {
  header, about, contact, contactUs,
};
