import './App.css';
import React, { useState, useEffect } from 'react';
import Aos from 'aos';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import {
  FaFacebookF, FaTwitter, FaInstagram,
  FaLinkedin,
} from 'react-icons/fa';
import Header from './components/Header';
import About from './components/About';
import Services from './components/services';
import OurClints from './components/ourClints';
import ContactMe from './components/ContactMe';
import 'aos/dist/aos.css';

function App() {
  const [lang, setLang] = useState(localStorage.getItem('lang') || 'Ar');

  const handleClick = () => {
    if (lang === 'Ar') {
      localStorage.setItem('lang', 'En');
      setLang('En');
    } else {
      localStorage.setItem('lang', 'Ar');
      setLang('Ar');
    }
  };
  useEffect(() => {
    Aos.init({
      duration: 2000,
      once: true,
    });
  }, []);

  return (
    <div className={`App sec ${(lang === 'Ar') ? 'rtl' : 'En'}`}>
      <Router>
        <div className="header">
          <nav className="navbar">
            <div className="banner">
              <div className="lang overflow-hidden">
                <Link
                  to="/"
                  className="px-2 "
                  onClick={handleClick}
                >
                  {lang === 'Ar' ? 'English' : 'عربي'}
                </Link>
              </div>
              <div className="icons">
                <Link to="https://www.facebook.com/teejanye" target="_blank" rel="noopener noreferrer" className="px-2">
                  <FaFacebookF className="banar-icon" />
                </Link>
                <Link to="https://twitter.com/teejanye" target="_blank" rel="noopener noreferrer" className="px-2">
                  <FaTwitter className="banar-icon" />
                </Link>
                {/* <Link to="/" target="_blank" rel="noopener noreferrer" className="px-2">
                <FaTelegram className="banar-icon" /></Link> */}
                <Link to="https://www.linkedin.com/in/teejanye" target="_blank" rel="noopener noreferrer" className="px-2">
                  <FaLinkedin className="banar-icon" />
                </Link>
                <Link to="https://www.instagram.com/teejanye" target="_blank" rel="noopener noreferrer" className="px-2">
                  <FaInstagram className="banar-icon" />
                </Link>
                {/* <Link to="/" target="_blank" rel="noopener noreferrer" className="px-2">
                <FaYoutube className="banar-icon" /></Link> */}
              </div>
            </div>
            <Header lang={lang} />
          </nav>
        </div>
      </Router>
      <About lang={lang} />
      <Services lang={lang} />
      <OurClints lang={lang} />
      <ContactMe lang={lang} />
    </div>
  );
}

export default App;
