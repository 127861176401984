import React from 'react';

function Footer() {
  return (
    <div className="footer">
      <div className="line" />
      <h6>Ⓒ Teejan 2024</h6>
      <div className="line" />
    </div>
  );
}

export default Footer;
