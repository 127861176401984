import ITService from '../../assets/services/IT-Service.svg';
import ManagementInfo from '../../assets/services/ManagementInformationService.svg';
import Marketingservice from '../../assets/services/Marketingservice.svg';
import PhoneService from '../../assets/services/PhoneService.svg';
import EarlyWarningSys from '../../assets/services/EarlyWarningSysServices.svg';

const servicesEn = [
  {
    id: 1,
    title: 'Mobile Services',
    icon: PhoneService,
    desc: `We provide a comprehensive range of mobile Value
         Added Services (VAS) to carriers which helps improve
          subscriber engagement and increase sales. Our VAS solutions include:`,
    dtl: [
      {
        id: '1',
        title: 'SMS (Short Message Service):',
        desc: `We provide SMS services for a variety of purposes, 
            such as marketing, customer service, notifications, 
            and informative services. Our SMS solutions are 
            highly scalable and can be used to reach a large number of 
            customers quickly and easily.`,
      },
      {
        id: '2',
        title: 'MMS (Multimedia Messaging Service):',
        desc: `We provide MMS services for sending and receiving images,
             videos, and other multimedia content. Our MMS solutions are perfect
              for businesses that want to create engaging and interactive marketing campaigns.`,
      },
      {
        id: '3',
        title: 'Mobile payments:',
        desc: `We provide mobile payments services for making payments at merchants,
             online, and in-app. Our mobile payments solutions are secure and convenient, 
             and they can help businesses to improve their cash flow and reduce costs.`,
      },
      {
        id: '4',
        title: 'Other VAS:',
        desc: `We also provide a variety of other VAS, such as ringtones,
             wallpapers, and games. Our VAS solutions are designed to meet 
             the specific needs of our clients, and we are always happy to discuss new ideas.`,
      },
    ],
  },
  {
    id: 2,
    title: 'Information Technology',
    icon: ITService,
    desc: `Managed services refer to the practice of outsourcing 
        the responsibility for maintaining and anticipating the need 
        for a range of processes and functions to improve operations 
        and cut expenses. also:`,
    dtl: [
      {
        id: '1',
        title: 'IT Support: ',
        desc: `Providing comprehensive technical support to ensure smooth 
            operation of IT systems and quick resolution of issues.`,
      },
      {
        id: '2',
        title: 'Server Management: ',
        desc: 'Managing servers to ensure optimal performance, security, and reliability.',
      },
      {
        id: '3',
        title: 'Network Management: ',
        desc: 'Monitoring and managing networks to maintain connectivity, security, and performance.',
      },
      {
        id: '4',
        title: 'Cost Reduction: ',
        desc: `Implementing strategies to reduce IT costs through 
            efficient resource utilization and budget optimization.`,
      },
      {
        id: '5',
        title: 'Operational Improvement: ',
        desc: `Implementing processes and 
            tools to enhance operational efficiency and productivity.`,
      },
      {
        id: '6',
        title: 'IT Staff Augmentation:  ',
        desc: `Providing skilled IT professionals to supplement internal IT 
            teams and fulfill strategic initiatives.`,
      },
      {
        id: '7',
        title: 'Security Enhancement:  ',
        desc: `Implementing measures to enhance 
            cybersecurity and protect against threats and vulnerabilities.`,
      },
      {
        id: '8',
        title: 'Backup and Disaster Recovery:  ',
        desc: 'Establishing backup systems and disaster recovery plans to ensure data integrity and business continuity.',
      },
      {
        id: '9',
        title: 'Proactive Monitoring:  ',
        desc: 'Utilizing monitoring tools and techniques to identify and address issues before they escalate, minimizing downtime and disruption.',
      },
    ],
  },
  {
    id: 3,
    title: 'Marketing',
    icon: Marketingservice,
    desc: 'We provide Marketing and E-Marketing Services tailored to business needs. ',
    dtl: [
      {
        id: '1',
        title: 'Marketing Services:',
        desc: `-  Graphic Designing
            - Creating companies Logos and full profile identity
            - Media Planning & Booking
            - Market research and analysis to identify opportunities and target audience.
            - Brand development and positioning to differentiate your business.
            - Advertising campaigns across various channels to increase visibility.
            - Public relations strategies to enhance your brand image and reputation.
            - Events Planning
            `,
      },
      {
        id: '2',
        title: 'E-Marketing Services:',
        desc: `- Motion & Animation
            - Search engine optimization (SEO) to improve your website's visibility in search results.
            - Social media marketing to engage with your audience and build brand loyalty.
            - Content creation and distribution to establish thought leadership and attract prospects.
            We understand the importance of a personalized approach. Our team of experts will work
             closely with you to develop strategies that align with your goals and objectives, ensuring maximum impact for your business.
            `,
      },
    ],
  },
  {
    id: 4,
    title: 'Early Warning Systems',
    icon: EarlyWarningSys,
    desc: `Our company specializes in implementing innovative 
        technologies and solutions to help communities and authorities
         mitigate the risks associated with flooding. Our systems are designed to
          monitor key indicators such as rainfall, river levels, and weather forecasts in real-time, allowing for early detection of potential flood events.`,
    dtl: [
      {
        id: '1',
        title: 'Early flood alerts:',
        desc: `By utilizing advanced sensors, data analytics,
             and communication networks, our early warning systems 
             can provide timely alerts to relevant stakeholders, 
             including government agencies, emergency responders, 
             and affected populations. These alerts enable proactive
              decision-making and rapid response actions, helping to minimize 
              the impact of floods on lives, property, and infrastructure.`,
      },
      {
        id: '2',
        title: 'Flood Early Warning Solutions: ',
        desc: `Our team of experts is dedicated to customizing and 
            implementing flood early warning solutions tailored to the 
            unique needs and challenges of each location. Whether it's 
            deploying sensor networks in flood-prone areas, integrating 
            data from multiple sources, or developing user-friendly interfaces
             for alert dissemination, we strive to provide comprehensive and reliable 
             solutions that enhance community resilience to flooding.`,
      },
    ],
  },
  {
    id: 5,
    title: 'Management Information Systems',
    icon: ManagementInfo,
    desc: `Our company specializes in offering comprehensive
         solutions to help organizations effectively manage their data,
          streamline operations, and make informed decisions.
           Our MIS solutions are tailored to meet the unique
            needs of each client, leveraging cutting-edge technology 
            and best practices to drive efficiency and productivity.`,
    dtl: [
      {
        id: '1',
        title: 'Comprehensive data solutions:',
        desc: `With a focus on delivering user-friendly interfaces and 
            robust functionality, we empower businesses to collect, store,
             analyze, and report on data from various sources across 
             their organization. Our systems facilitate real-time access
              to critical information, enabling stakeholders at all levels
               to track performance, identify trends, and respond quickly 
               to changing market conditions.`,
      },
    ],
  },
];

export default servicesEn;
