import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, FormattedMessage } from 'react-intl';
import { FaCheck } from 'react-icons/fa6';
import { header } from './Data/mainDataFile';
import img from '../assets/HeaderIcon.svg';
import servicesAr from './Data/dataFile';
import servicesEn from './Data/dataFileEn';

function Services({ lang }) {
  const [data, setData] = useState(null);
  const [services, setServices] = useState([]);
  const [active, setActive] = useState(1);
  const swiperElRef = useRef(null);

  const getDataById = (id) => {
    setData(services.find((srv) => srv.id === id));
  };

  const hendleClick = (id) => {
    getDataById(id);
    setActive(id);
  };

  const handleKeyPress = (event, id) => {
    if (event.key === 'Enter') {
      hendleClick(id);
    }
  };

  useEffect(() => {
    if (lang === 'Ar') {
      setServices(servicesAr);
    } else {
      setServices(servicesEn);
    }
    hendleClick(1);
  }, [lang, services]);

  useEffect(() => {
    const { swiper } = swiperElRef.current;
    if (swiper) {
      swiper.on('slideChange', () => {
        getDataById(swiper.activeIndex + 1);
      });
    }
  }, [getDataById]);

  return (
    <div className="sec" data-aos="fade-up">
      <IntlProvider locale={lang} messages={header[lang]}>
        <div className="servicesBack">
          <div className="yellowBlur" />
        </div>
        <div className="servicesBack">
          <div className="redBlur w-100" />
        </div>
        <div className="Services" id="Services">
          <div className="title">
            <img src={img} alt="img3" />
            <h2>
              {' '}
              <FormattedMessage
                id="Services"
                defaultMessage="some default one"
                values={{ lang }}
              />
            </h2>
            <img src={img} alt="img4" />
          </div>
          <div className="carosuel">
            <swiper-container
              style={
          {
            '--swiper-navigation-color': '#E02229',
            '--swiper-pagination-color': '#E02229',
            '--swiper-navigation-size': '25px',
          }
      }
              pagination-clickable="true"
              center-slides="true"
              navigation="true"
              grab-cursor="false"
              cube-effect-shadow="false"
              ref={swiperElRef}
            >
              {services && services.map((srv, i = 0) => (
                <swiper-slide
                  key={i + 1}
                  value={srv.id}
                  tabIndex={0}
                >
                  <img
                    src={srv.icon}
                    alt={srv.title}
                  />
                  <h4 className="p-2">{srv.title}</h4>
                </swiper-slide>
              ))}
            </swiper-container>
          </div>
          <div className="decktop">
            <div
              className="Services-images"
            >
              {services && services.map((srv, i = 0) => (
                <div
                  key={i + 1}
                  value={srv.id}
                  onClick={() => hendleClick(srv.id)}
                  onKeyPress={(e) => handleKeyPress(e, srv.id)}
                  className={`serviseDiv ${(srv.id === active) ? 'active' : ''}`}
                  role="button"
                  tabIndex={0}
                >
                  <img src={srv.icon} alt={srv.title} className={`serviseImg ${(srv.id === active) ? 'activeImg' : ''}`} />
                  <h4 className="p-2">{srv.title}</h4>
                </div>
              ))}
            </div>

          </div>
          {data && (
          <div data-aos={`${lang === 'En' ? 'fade-left' : 'fade-right'}`} className="sec detils">
            <div className="mainData">
              <div className="ServicesIcon ">
                <img src={data.icon} alt={data.title} />
              </div>

              <div className="ServicesDesc">
                <h3>{data.title}</h3>
                <p>{data.desc}</p>
              </div>
            </div>

            {data.dtl.map((dt, i = 0) => (
              <div data-aos={`${lang === 'En' ? 'fade-left' : 'fade-right'}`} key={i + 1} className="sec data ">
                <FaCheck className="checkIcon" />
                <div className="data-dtl">
                  <h6>{dt.title}</h6>
                  <p>{dt.desc}</p>
                </div>
              </div>
            ))}
          </div>
          )}
        </div>
      </IntlProvider>
    </div>
  );
}

Services.propTypes = {
  lang: PropTypes.string.isRequired,
};
export default Services;
