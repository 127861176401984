import { IntlProvider, FormattedMessage } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import { about } from './Data/mainDataFile';
import logo from '../assets/Teejan.svg';
import under from '../assets/underImg.svg';

function About({ lang }) {
  return (
    <>
      <IntlProvider locale={lang} messages={about[lang]}>
        <div className={`back ${(lang === 'En') ? 'transform' : ''}`} id="About">
          <div className="blur" />
        </div>
        <div className="infos">
          <div className="logo">
            <img src={logo} className="logo" alt="1" />
          </div>
          <h2 className="p-3">
            <FormattedMessage
              id="titl"
              defaultMessage="some default one"
              values={{ lang }}
            />
          </h2>
          <p>
            <FormattedMessage
              id="desc"
              defaultMessage="some default one"
              values={{ lang }}
            />
          </p>
          <div className="under">
            <img src={under} className="underImg" alt="2" />
          </div>
        </div>

      </IntlProvider>
    </>
  );
}

About.propTypes = {
  lang: PropTypes.string.isRequired,
};
export default About;
