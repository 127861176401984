import React from 'react';
import { IntlProvider, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { header } from './Data/mainDataFile';
import img from '../assets/HeaderIcon.svg';
import ourClientsData from './Data/ourClintsData '; // Assuming the correct filename

function OurClients({ lang }) {
  const data = () => (
    <div className="logos-slide">
      {ourClientsData.map((client) => (
        <img key={client.id} src={client.src} alt={client.alt} />
      ))}
    </div>
  );
  return (
    <section data-aos="fade-up" className="sec">
      <IntlProvider locale={lang} messages={header[lang]}>
        <div className="ourClint title" id="Sectors">
          <img src={img} alt="img5" />
          <h2>
            {' '}
            <FormattedMessage
              id="skils"
              defaultMessage="some default one"
              values={{ lang }}
            />
          </h2>
          <img src={img} alt="img6" />
        </div>
        <div className="logos">
          {data()}
          {data()}
          {data()}
        </div>

      </IntlProvider>
    </section>
  );
}
OurClients.propTypes = {
  lang: PropTypes.string.isRequired,
};
export default OurClients;
