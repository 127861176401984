import { IntlProvider, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { header } from './Data/mainDataFile';
import who from '../assets/HeaderIcon.svg';
import cntct from '../assets/contact.svg';
import srv from '../assets/srv.svg';
import vctor from '../assets/Vector.svg';

function Header({ lang }) {
  return (
    <IntlProvider locale={lang} messages={header[lang]}>
      <div className=" nav-nav">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link red" aria-current="page" href="#About">
              <img src={who} alt="img1" className="h-img" />
              <p>
                <FormattedMessage
                  id="teejan"
                  defaultMessage="some default one"
                  values={{ lang }}
                />
              </p>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link main-color" aria-current="page" href="#Services">
              <img src={srv} alt="img1" className="h-img" />
              <p>
                <FormattedMessage
                  id="Services"
                  defaultMessage="some default one"
                  values={{ lang }}
                />
              </p>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link main-color" href="#Sectors">
              <img src={vctor} alt="img1" className="h-img" />
              <p>
                <FormattedMessage
                  id="skils"
                  defaultMessage="some default one"
                  values={{ lang }}
                />
              </p>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link main-color" href="#ContactMe">
              <img src={cntct} alt="img1" className="h-img" />
              <p>
                <FormattedMessage
                  id="Contact"
                  defaultMessage="some default one"
                  values={{ lang }}
                />
              </p>
            </a>
          </li>
        </ul>
      </div>
    </IntlProvider>
  );
}
Header.propTypes = {
  lang: PropTypes.string.isRequired,
};
export default Header;
