import ITService from '../../assets/services/IT-Service.svg';
import ManagementInfo from '../../assets/services/ManagementInformationService.svg';
import Marketingservice from '../../assets/services/Marketingservice.svg';
import PhoneService from '../../assets/services/PhoneService.svg';
import EarlyWarningSys from '../../assets/services/EarlyWarningSysServices.svg';

const servicesAr = [
  {
    id: 1,
    title: 'خدمات الهاتف النقال',
    icon: PhoneService,
    desc: `نقدم مجموعة شاملة
     من الخدمات ذات القيمة المضافة للهاتف النقال 
     (VAS) شركات الاتصالات مما يساعد على تحسين تفاعل المشتركين وزيادة المبيعات. تشمل حلول الـ VAS لدينا:`,
    dtl: [
      {
        id: '1',
        title: 'خدمة الرسائل القصيرة :(SMS)',
        desc: `نقدم خدمات الرسائل القصيرة لمجموعة متنوعة من الأغراض،
        مثل التسويق، وخدمة العملاء، والإشعارات والخدمات المعلوماتية. تتميز حلول الرسائل القصيرة لدينا بسهولة التوسع،
         ويمكن استخدامها للوصول إلى عدد كبير من العملاء بسرعة وسهولة.
        `,
      },
      {
        id: '2',
        title: 'خدمة رسائل الوسائط المتعددة (MMS)',
        desc: `نقدم خدمة رسائل الوسائط المتعددة (MMS) لإرسال 
        واستقبال الصور ومقاطع الفيديو والمحتوى المتعدد الوسائط. تعتبر حلول 
        MMS لدينا مثالية للشركات التي ترغب في إنشاء حملات تسويقية`,
      },
      {
        id: '3',
        title: 'الدفع عبر الهاتف الجوّال:',
        desc: `نُوفّر خدمات الدفع عبر الهاتف الجوّال للتسديد لدى المتاجر وفي المواقع الإلكترونية
         والتطبيقات. تتميّز حلول الدفع عبر الهاتف الجوّال لدينا بالأمان والراحة، 
        وتساعد الشركات على تحسين تدفق السيولة النقدية لديها وتقليل التكاليف.`,
      },
      {
        id: '4',
        title: 'خدمات إضافية ذات قيمة مضافة:',
        desc: `نقدم أيضًا مجموعة متنوعة من الخدمات الأخرى ذات القيمة المضافة، مثل: نغمات الهاتف 
        - خلفيات الشاشة - الألعاب. نحن على
         استعداد دائمًا لتلبية الاحتياجات
         المحددة لعملائنا، ويسعدنا مناقشة
         أي أفكار جديدة قد تكون لديهم`,
      },
    ],
  },
  {
    id: 2,
    title: 'تكنولوجيا المعلومات ',
    icon: ITService,
    desc: `تشير خدمات المراقبة إلى ممارسة تفويض مسؤولية الصيانة
     والتنبؤ بالحاجة إلى مجموعة من العمليات والوظائف لتحسين 
     العمليات وتقليل التكاليف. فيما يلي نظرة عامة موجزة بالإضافة إلى:`,
    dtl: [
      {
        id: '1',
        title: 'دعم تقنية المعلومات:',
        desc: 'توفير دعم تقني شامل لضمان سلاسة تشغيل أنظمة تقنية المعلومات وحل المشكلات بسرعة',
      },

      {
        id: '2',
        title: 'إدارة الخوادم: ',
        desc: 'إدارة الخوادم لضمان الأداء والأمان والموثوقية الأمثل.',
      },
      {
        id: '3',
        title: 'إدارة الشبكات: ',
        desc: 'رصد وإدارة الشبكات للحفاظ على الاتصال والأمان والأداء.',
      },
      {
        id: '4',
        title: 'تقليل التكاليف: ',
        desc: 'تنفيذ استراتيجيات لتقليل تكاليف تقنية المعلومات من خلال استخدام الموارد بكفاءة وتحسين الميزانية.',
      },
      {
        id: '5',
        title: 'تحسين العمليات: ',
        desc: 'تنفيذ عمليات وأدوات لتعزيز الكفاءة التشغيلية وزيادة الإنتاجية.',
      },
      {
        id: '6',
        title: 'زيادة الكوادر في تقنية المعلومات:',
        desc: 'تقديم محترفي تقنية المعلومات المهرة لتعزيز فرق التقنية الداخلية وتنفيذ المبادرات الاستراتيجية.',
      },
      {
        id: '7',
        title: 'تعزيز الأمان: ',
        desc: 'تنفيذ تدابير لتعزيز الأمان السيبراني وحماية ضد التهديدات والثغرات.',
      },
      {
        id: '8',
        title: 'النسخ الاحتياطي واستعادة البيانات:',
        desc: 'إنشاء أنظمة النسخ الاحتياطي وخطط الاستعادة للبيانات لضمان سلامة البيانات واستمرارية الأعمال.',
      },
      {
        id: '9',
        title: 'المراقبة الاحترافية: ',
        desc: `استخدام أدوات المراقبة والتقنيات لتحديد
         ومعالجة المشكلات قبل تفاقمها، مما يقلل من الوقت التوقف والانقطاع.`,
      },
    ],
  },
  {
    id: 3,
    title: 'التسويق',
    icon: Marketingservice,
    desc: 'نحن نقدم خدمات التسويق والتسويق الإلكتروني مصممة حسب احتياجات الأعمال.',
    dtl: [
      {
        id: '1',
        title: 'خدمات التسويق:',
        desc: `- تصميم الجرافيك
        - إنشاء شعارات الشركات والهوية الكاملة للملف الشخصي
        - التخطيط والحجز الإعلامي
        - أبحاث السوق والتحليل لتحديد الفرص والجمهور المستهدف
        - تطوير العلامة التجارية وتحديد موقعها لتمييز عملك
        - حملات الإعلان عبر مختلف القنوات لزيادة الرؤية
        - استراتيجيات العلاقات العامة لتعزيز صورة علامتك التجارية وسمعتها
        - تخطيط الفعاليات
        `,
      },
      {
        id: '2',
        title: 'خدمات التسويق الإلكتروني:',
        desc: `- الحركة والرسوم المتحركة
        - تحسين محركات البحث (SEO) لزيادة رؤية موقعك على الويب في نتائج البحث
        - التسويق عبر وسائل التواصل الاجتماعي للتفاعل مع جمهورك وبناء الولاء للعلامة التجارية
        - إنشاء المحتوى وتوزيعه لتأسيس الريادة في الفكر وجذب العملاء المحتملين
        
        نحن ندرك أهمية النهج الشخصي. سيعمل فريق خبرائنا عن كثب معك لتطوير استراتيجيات تتماشى مع أهدافك ومبادئك، مما يضمن أقصى تأثير لعملك.
        `,
      },
    ],
  },
  {
    id: 4,
    title: 'أنظمة الإنذار المبكر',
    icon: EarlyWarningSys,
    desc: '',
    dtl: [
      {
        id: '1',
        title: 'تنبيهات مبكرة للفيضانات: ',
        desc: `من خلال استخدام الحساسات المتقدمة وتحليل البيانات و
        الشبكات الاتصالات، يمكن لأنظمتنا للتحذير المبكر توفير تنبيهات في الوقت المناسب للجهات 
        المعنية ذات الصلة، بما في ذلك الجهات الحكومية والمتصرفين الطارئين
         والسكان المتأثرين. تتيح هذه التنبيهات اتخاذ قرارات تدبيرية
         مسبقة واتخاذ إجراءات استجابة سريعة، مما يساعد في تقليل 
        تأثير الفيضانات على الأرواح والممتلكات والبنية التحتية.`,
      },
      {
        id: '2',
        title: 'حلول التحذير المبكر من الفيضانات: ',
        desc: `يقوم فريقنا من الخبراء بتخصيص وتنفيذ حلول التحذير المبكر من الفيضانات وفقًا لاحتياجات 
        وتحديات كل موقع بشكل فريد. سواء كان ذلك من خلال نشر شبكات الاستشعار في
         المناطق المعرضة للفيضانات أو دمج البيانات
         من مصادر متعددة أو تطوير واجهات مستخدم سهلة
         الاستخدام لنشر التنبيهات، نسعى لتوفير حلول شاملة
         وموثوقة تعزز من قدرة المجتمع على التحمل للفيضانات`,
      },
    ],
  },
  {
    id: 5,
    title: 'أنظمة إدارة المعلومات',
    icon: ManagementInfo,
    desc: `تخصص شركتنا في تقديم حلول شاملة لمساعدة المؤسسات على إدارة بياناتها بفعالية،
     وتبسيط العمليات،
      واتخاذ القرارات القائمة على الحقائق. تتمحور حلولنا MIS حول تلبية احتياجات كل عميل بشكل فريد، واستغلال التكنولوجيا المتطورة وأفضل الممارسات لتعزيز الكفاءة
      والإنتاجية.`,
    dtl: [
      {
        id: '1',
        title: 'حلول البيانات الشاملة:',
        desc: `من خلال التركيز على توفير واجهات سهلة الاستخدام ووظائف قوية، نمكّن الشركات من جمع البيانات،
         وتخزينها، وتحليلها، وتقديم تقارير عنها من 
        مصادر متعددة عبر المؤسسة. تسهل نظمنا الوصول في
         الوقت الفعلي إلى المعلومات الحيوية، مما يمكن 
        أصحاب المصلحة على جميع المستويات من تتبع الأداء
        ، وتحديد الاتجاهات، والاستجابة بسرعة لتغيرات السوق.`,
      },
    ],
  },
];

export default servicesAr;
